@import '@css/fonts';
@import '@css/variables';
@import '~bootstrap/scss/bootstrap-grid';

.experience-comp {
  display: grid;
  width: 100%;
  grid-template-columns: minmax(0, 1fr);
  gap: 2em;
  font-size: 1rem;

  @include media-breakpoint-up(lg) {
    &:not(.bot) {
      grid-template-columns: minmax(100px, 150px) minmax(0, 1fr);
      gap: 1em;
    }
  }

  .experience {
    display: block;
    margin-bottom: 1.5em;

    .job-name {
      font-size: 1.5em;
    }
    
    .details {
      font-family: $font-sfmono;
      margin-bottom: 2em;
      font-size: .9em;
  
      p {
        margin-bottom: .5em;
  
        &~p {
          margin-bottom: 0;
        }
      }
    }
  
    .description {
      ul {
        list-style-type: none;
        padding: 0;
  
        li {
          position: relative;
          margin-bottom: 10px;
          padding-left: 30px;
  
          &:before {
              content: "▹";
              position: absolute;
              left: 0px;
              color: $malibu;
          }
        }
      }
    }
  }
}