@import '@css/fonts';
@import '@css/variables';
@import '~bootstrap/scss/bootstrap-grid';

.trail-list-comp {
  font-family: $font-sfmono;
  font-size: 1rem;
  display: block;
  width: 100%;

  .list-container {
    width: 100%;
    position: relative;
    
    // Horizontal layout
    display: block;
    white-space: nowrap;
    overflow: hidden;
    overflow-x: auto;

    > .list-elem {
      width: auto;
      text-align: left;
      display: inline-block;
    }
    
    // Vertical layout
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
  
      > .list-elem {
        width: 100%;
        text-align: left;
        display: block;
      }
    }
  }

  .list-elem {
    background-color: $blue-zodiac;
    font-size: .85em;
    border: 0 none;
    outline: 0 none;
    color: $bali-hai;
    transition: color 0.25s linear, background-color 0.25s linear;
    will-change: color, background-color;
    border-style: solid;
    border-color: $pickled-bluewood;
    max-width: 100%;

    // Horizontal layout
    border-width: 0 0 2px 0;
    padding: 1.3em 2em;
    white-space: nowrap;

    // Vertical layout
    @include media-breakpoint-up(lg) {
      border-width: 0 0 0 2px;
      padding: 1.3em 1em;
      white-space: pre-wrap;
    }

    &:hover {
      background-color: $pickled-bluewood;
    }

    &.active {
      color: $malibu;
    }
  }

  .plate {
    background-color: $malibu;
    position: absolute;
    bottom: auto;
    display: block;
    transition: transform 0.25s linear 0.1s, width 0.25s linear 0.1s, height 0.25s linear 0.1s;
    will-change: transform, width, height;
    width: 0;
    height: 0;

    &.horizontal {
      bottom: 0;
      left: 0;
      display: block;
      
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &.vertical {
      top: 0;
      left: 0;
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;
      }

    }
  }
}